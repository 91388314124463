import React, { useContext, useMemo, useRef } from "react";
import { EtiquetaSeguridad } from "./EtiquetaSeguridad.tsx";
import { DataContext } from "../context/DataContext.tsx";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import { EtiquetasPrintPage } from "./EtiquetasPrintPage.tsx";

export const EtiquetasSeguridad: React.FC = ({ asin }: any) => {
  const { allProducts } = useContext(DataContext);
  console.log("allProducts", allProducts[asin]);

  const data = useMemo(() => {
    if (allProducts[asin] === undefined) {
      return null;
    }
    const prod = allProducts[asin];
    console.log({
      title: prod.spanish.title,
      img: prod.spanish.images.MAIN,
      modelName: prod.modelName,
      brand: prod.brand,
    });

    return {
      title: prod.spanish.title,
      img: prod.spanish.images.MAIN,
      modelName: prod.modelName,
      brand: prod.brand,
    };
  }, [asin, allProducts]);

  if (allProducts[asin] === undefined) {
    return <div>Producto no encontrado</div>;
  }

  const etiquetas = Array.from({ length: 40 }, (_, i) => `Etiqueta ${i + 1}`);

  return (
    <>{etiquetas && <EtiquetasPrintPage etiquetas={etiquetas} data={data} />}</>
  );
};
