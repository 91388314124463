/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Switch,
  Tabs,
  TabsProps,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { ProductLanguageForm } from "./ProductLanguageForm/ProductLanguageForm.tsx";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { productFormDefaultValues } from "./productFormDefaultValues.ts";
import { ErrorMessage } from "./ErrorMessage.tsx";
import { UploadService } from "../services/UploadService.ts";
import { Link, useLocation, useSearch } from "wouter";
import { ProductListModalSelectable } from "./ProductListModalSelectable.tsx";
import { WarehouseService } from "../services/WarehouseService.ts";
import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { Flag } from "./flags/Flag.tsx";
import { DGCKeditor } from "./DGCKeditor.tsx";
import { MARKETPLACES_IDS } from "../config.ts";
import { SpApiService } from "../services/SpApiService.ts";
import { IoMdWarning } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";
import { IoMdStar } from "react-icons/io";
import dayjs from "dayjs";
import { OrderService } from "../services/OrderService.ts";
import { navigate } from "wouter/use-browser-location";
import { UnitSelect } from "./UnitSelect.tsx";

export const ProductForm = ({ asin, asinService, onDeleteAsin, onBack }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [location, setLocation] = useLocation();
  const searchString = useSearch();
  const [languageTab, setLanguageTab] = useState(
    searchString.split("=")[1] || "spanish"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(
    searchString.split("=")[1] || "spanish"
  );
  const [isLoadingZip, setIsLoadingZip] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [zipFileUrl, setZipFileUrl] = useState<string[]>([]);
  const [showProductListSelector, setShowProductListSelector] = useState(false);
  const [isBaseProduct, setIsBaseProduct] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [asinData, setAsinData] = useState<any>(null);
  const [awsData, setAwsData] = useState<any>(null);
  const form = useForm({
    defaultValues: { ...productFormDefaultValues, asin },
    mode: "all",
  });
  const { getValues } = form;
  const values = getValues();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "composedBy",
  });

  const {
    fields: warehouseStockFields,
    append: appendWarehouseStock,
    remove: removeWarehouseStock,
  } = useFieldArray({
    control: form.control,
    name: "warehouseStock",
  });

  useEffect(() => {
    const favorites = localStorage.getItem("favorites");
    if (favorites) {
      setIsFavorite(favorites.includes(asin));
    }
    WarehouseService.getAllWarehouses().then((res) => {
      setWarehouses(res.data);
    });
    asinService.getAsin(asin).then((res) => {
      document.title = res.data.spanish.title;
      form.reset(res.data);
      setAsinData(res.data);
      setIsBaseProduct(res.data.isBaseProduct);
    });

    const hasSku = !(
      form.getValues().sku === "" ||
      form.getValues().sku === undefined ||
      form.getValues().sku === null
    );

    if (hasSku) {
      getAllDataFromMarketplaces();
    } else {
      setIsLoading(false);
    }
  }, [asin]);
  // }, [asin, values.sku]); //cambie esto por que se hacian llamadas infinitas en bucle

  useEffect(() => {
    const hasSku = !(
      form.getValues().sku === "" ||
      form.getValues().sku === undefined ||
      form.getValues().sku === null
    );

    if (hasSku) {
      getAllDataFromMarketplaces();
    }
  }, [values.sku]);

  const getAllDataFromMarketplaces = async () => {
    setIsLoading(true);
    let aux = {};
    for (const marketplace of MARKETPLACES_IDS) {
      await SpApiService.getAwsProductAttributes(
        form.getValues().sku,
        marketplace.marketplaceId
      )
        .then((res) => {
          aux[marketplace.key] = res.data;
        })
        .catch(() => {
          aux[marketplace.key] = null;
        });
    }
    setIsLoading(false);
    setAwsData(aux);
  };

  useEffect(() => {
    console.log("awsData", awsData);
  }, [awsData]);

  const items: TabsProps["items"] = [
    {
      key: "spanish",
      label: (
        <Tooltip
          title="Idioma nativo: Español (castellano)
Idioma más utilizado: Español (castellano)"
        >
          <div>
            <Flag lang={"spanish"} />
            <span>Espanol</span>{" "}
            <Tooltip title="Precio dg">
              <span>{form.getValues()?.spanish?.list_price}</span>
            </Tooltip>
            <span>/</span>
            <Tooltip title="Precio oferta aws">
              <span className="violet">
                {awsData?.spanish?.attributes?.purchasable_offer?.[0]
                  ?.our_price?.[0]?.schedule?.[0]?.value_with_tax || (
                  <IoMdWarning
                    style={{
                      color: "orangered",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "german",
      label: (
        <Tooltip
          title="Idioma nativo: Alemán
Idioma más utilizado: Alemán
"
        >
          <div>
            <Flag lang={"german"} />
            <span>Aleman</span>{" "}
            <Tooltip title="Precio dg">
              <span>{form.getValues()?.german?.list_price}</span>
            </Tooltip>
            <span>/</span>
            <Tooltip title="Precio oferta aws">
              <span className="violet">
                {awsData?.german?.attributes?.purchasable_offer?.[0]
                  ?.our_price?.[0]?.schedule?.[0]?.value_with_tax || (
                  <IoMdWarning
                    style={{
                      color: "orangered",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "french",
      label: (
        <Tooltip
          title="Idioma nativo: Francés
Idioma más utilizado: Francés
"
        >
          <div>
            <Flag lang={"french"} />
            <span>Frances</span>{" "}
            <Tooltip title="Precio dg">
              <span>{form.getValues()?.french?.list_price}</span>
            </Tooltip>
            <span>/</span>
            <Tooltip title="Precio oferta aws">
              <span className="violet">
                {awsData?.french?.attributes?.purchasable_offer?.[0]
                  ?.our_price?.[0]?.schedule?.[0]?.value_with_tax || (
                  <IoMdWarning
                    style={{
                      color: "orangered",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "italian",
      label: (
        <Tooltip
          title="Idioma nativo: Italiano
Idioma más utilizado: Italiano"
        >
          <div>
            <Flag lang={"italian"} />
            <span>Italia</span>{" "}
            <Tooltip title="Precio dg">
              <span>{form.getValues()?.italian?.list_price}</span>
            </Tooltip>
            <span>/</span>
            <Tooltip title="Precio oferta aws">
              <span className="violet">
                {awsData?.italian?.attributes?.purchasable_offer?.[0]
                  ?.our_price?.[0]?.schedule?.[0]?.value_with_tax || (
                  <IoMdWarning
                    style={{
                      color: "orangered",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "belgium",
      label: (
        <Tooltip
          title="Idiomas nativos: Neerlandés (flamenco), Francés, Alemán
Idiomas más utilizados: Francés y Neerlandés (flamenco)
"
        >
          <div>
            <Flag lang={"belgium"} />
            <span>Bélgica</span>{" "}
            <Tooltip title="Precio dg">
              <span>{form.getValues()?.belgium?.list_price}</span>
            </Tooltip>
            <span>/</span>
            <Tooltip title="Precio oferta aws">
              <span className="violet">
                {awsData?.belgium?.attributes?.purchasable_offer?.[0]
                  ?.our_price?.[0]?.schedule?.[0]?.value_with_tax || (
                  <IoMdWarning
                    style={{
                      color: "orangered",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "netherlands",
      label: (
        <Tooltip
          title="Idioma nativo: Neerlandés (holandés)
Idioma más utilizado: Neerlandés (holandés)
"
        >
          <div>
            <Flag lang={"netherlands"} />
            <span>Paises bajos</span>{" "}
            <Tooltip title="Precio dg">
              <span>{form.getValues()?.netherlands?.list_price}</span>
            </Tooltip>
            <span>/</span>
            <Tooltip title="Precio oferta aws">
              <span className="violet">
                {awsData?.netherlands?.attributes?.purchasable_offer?.[0]
                  ?.our_price?.[0]?.schedule?.[0]?.value_with_tax || (
                  <IoMdWarning
                    style={{
                      color: "orangered",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "poland",
      label: (
        <Tooltip
          title="Idioma nativo: Polaco
Idioma más utilizado: Polaco
"
        >
          <div>
            <Flag lang={"poland"} />
            <span>Polonia</span>{" "}
            <Tooltip title="Precio dg">
              <span>{form.getValues()?.poland?.list_price}</span>
            </Tooltip>
            <span>/</span>
            <Tooltip title="Precio oferta aws">
              <span className="violet">
                {awsData?.poland?.attributes?.purchasable_offer?.[0]
                  ?.our_price?.[0]?.schedule?.[0]?.value_with_tax || (
                  <IoMdWarning
                    style={{
                      color: "orangered",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "sweden",
      label: (
        <Tooltip
          title="Idioma nativo: Sueco
Idioma más utilizado: Sueco
"
        >
          <div>
            <Flag lang={"sweden"} />
            <span>Suecia</span>{" "}
            <Tooltip title="Precio dg">
              <span>{form.getValues()?.sweden?.list_price}</span>
            </Tooltip>
            <span>/</span>
            <Tooltip title="Precio oferta aws">
              <span className="violet">
                {awsData?.sweden?.attributes?.purchasable_offer?.[0]
                  ?.our_price?.[0]?.schedule?.[0]?.value_with_tax || (
                  <IoMdWarning
                    style={{
                      color: "orangered",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </Tooltip>
      ),
    },
  ];

  const handleSubmit = () => {
    const values = form.getValues();

    asinService.updateAsin(values.asin, values).then((res) => {
      notification.success({
        message: "Product updated",
        description: "Product updated successfully",
      });
    });
  };

  const handleGenerateZip = async () => {
    try {
      setIsLoadingZip(true);
      const values = form.getValues();
      for (let index = 0; index < MARKETPLACES_IDS.length; index++) {
        const marketplace = MARKETPLACES_IDS[index];
        const res = await UploadService.generateZip(
          values.asin,
          marketplace.key
        );
        notification.info({
          message: `${index + 1}/${MARKETPLACES_IDS.length} Zip file created`,
        });

        setZipFileUrl((zipFileUrl: any) => [...zipFileUrl, res]);
      }

      setIsLoadingZip(false);
      notification.success({
        message: "Zip file created",
        description: "Zip file created successfully",
      });
    } catch (error) {
      setIsLoadingZip(false);
      notification.error({
        message: "Error al generar el zip",
        description: "Error al generar el zip",
      });
    }
  };

  const handleAddProduct = (data) => {
    const values = form.getValues();
    form.setValue("composedBy", [...values.composedBy, ...data]);
    setShowProductListSelector(false);
  };

  const handleComposeProduct = () => {
    setShowProductListSelector(true);
  };

  const handleDownloadAllFiles = () => {
    zipFileUrl.forEach((url) => {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", url.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  const handleCreateSecureTags = () => {
    console.log("handleCreateSecureTags" + asin);
    navigate(`/products/${asin}/tags`);
  };

  return (
    <>
      {showProductListSelector && (
        <ProductListModalSelectable
          showOnlyBaseProducts={true}
          showOnlyComposedProducts={false}
          handleOk={handleAddProduct}
          handleCancel={() => {
            setShowProductListSelector(false);
          }}
        />
      )}

      {showDeleteModal && (
        <Modal
          title="Delete"
          open={true}
          onOk={() => {
            asinService.deleteAsin(asin).then(() => {
              setShowDeleteModal(false);
              onDeleteAsin();
              notification.success({
                message: "Producto borrado",
                description: "Producto borrado correctamente",
              });
              setLocation("/products");
            });
          }}
          onCancel={() => setShowDeleteModal(false)}
        >
          Seguro que quieres borrar este asin? Esta acción no se puede
          deshacer!!!
        </Modal>
      )}
      <div
        style={{
          padding: "0 20px 20px 20px",
          marginTop: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "absolute",
            width: "80%",
            backgroundColor: "#e9e9e9",
            zIndex: 10,
            borderBottom: "1px solid #666",
            top: 0,
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {asinData && (
            <img
              height={50}
              width={50}
              src={`${asinData?.spanish?.images?.MAIN}`}
              alt="asin"
            />
          )}
          <div className="d-flex-center">
            {isFavorite && (
              <IoMdStar
                style={{
                  color: "orangered",
                  marginLeft: 10,
                  cursor: "pointer",
                  fontSize: 24,
                }}
                onClick={() => {
                  setIsFavorite(false);
                  const favorites: any = localStorage.getItem("favorites");
                  const aux = JSON.parse(favorites || []).filter(
                    (el) => el !== asin
                  );
                  localStorage.setItem("favorites", JSON.stringify(aux));
                }}
              />
            )}
            {!isFavorite && (
              <IoIosStarOutline
                style={{
                  fontSize: 24,
                  color: "orangered",
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsFavorite(true);
                  const favorites = localStorage.getItem("favorites");
                  if (favorites) {
                    const aux = JSON.parse(favorites);
                    aux.push(asin);
                    localStorage.setItem("favorites", JSON.stringify(aux));
                  } else {
                    localStorage.setItem("favorites", JSON.stringify([asin]));
                  }
                }}
              />
            )}
            <h1 style={{ alignSelf: "flex-end" }}>
              Asin: {asin} <Flag lang={selectedLanguage} width={50} />
            </h1>
          </div>
          <div>
            <Link to={`/products/${asin}/docs`}>
              Docs ({asinData?.docs?.length || 0})
            </Link>
          </div>
          <Button
            disabled={!form.formState.isValid}
            type="primary"
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            marginBottom: "14px",
          }}
        >
          <Button type="primary" onClick={handleGenerateZip}>
            {isLoadingZip && (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 16, color: "white" }}
                    spin
                  />
                }
              />
            )}
            Generar Zip
          </Button>
        </div>
        <div className="dg-item">
          <Button onClick={handleCreateSecureTags} type="primary">
            Etiquetas seguridad
          </Button>
        </div>
        {zipFileUrl.length > 0 && isLoadingZip === false && (
          <div
            className="dg-item"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button onClick={handleDownloadAllFiles}>Descargar todos</Button>
          </div>
        )}

        {zipFileUrl.length > 0 && isLoadingZip === false && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "24px",
            }}
          >
            <a
              href="https://sellercentral.amazon.es/imaging/upload/country"
              target="_blank"
              rel="noreferrer"
            >
              Ir a la pagina para subir los archivos por pais
            </a>
          </div>
        )}

        <div className="d-flex dg-item">
          <div style={{ marginRight: "20px" }}>
            <div>
              digitalGoodsPublish{" "}
              <Controller
                name="digitalGoodsPublish"
                control={form.control}
                rules={{
                  pattern: {
                    value: /^\d+(\.\d+)?$/,
                    message: "Debe ser un número decimal con punto",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Switch
                      checked={field.value}
                      onChange={(_value) => {
                        console.log("_value", _value);
                        form.setValue("digitalGoodsPublish", _value);
                      }}
                    />

                    <ErrorMessage form={form} name="weight" />
                  </>
                )}
              />
            </div>
            <div>
              Peso{" "}
              <Controller
                disabled={!isBaseProduct}
                name="weight"
                control={form.control}
                rules={{
                  pattern: {
                    value: /^\d+(\.\d+)?$/,
                    message: "Debe ser un número decimal con punto",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="weight" />
                  </>
                )}
              />
            </div>
            <div>
              <Tooltip title="item_dimensions_height">
                Alto producto (height){" "}
              </Tooltip>
              <Controller
                name="item_dimensions_height"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="height" />
                  </>
                )}
              />
            </div>
            <div>
              <Tooltip title="item_dimensions_width">
                Ancho producto (width){" "}
              </Tooltip>
              <Controller
                name="item_dimensions_width"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="width" />
                  </>
                )}
              />
            </div>
            <div>
              <Tooltip title="item_dimensions_length">
                Profundo producto (length){" "}
              </Tooltip>
              <Controller
                name="item_dimensions_length"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="depth" />
                  </>
                )}
              />
            </div>
            <div>
              <Tooltip title="item_package_dimensions_height">
                Alto paquete (height){" "}
              </Tooltip>
              <Controller
                name="item_package_dimensions_height"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="packageHeight" />
                  </>
                )}
              />
            </div>
            <div>
              <Tooltip title="item_package_dimensions_width">
                Ancho paquete (width){" "}
              </Tooltip>
              <Controller
                name="item_package_dimensions_width"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="packageWidth" />
                  </>
                )}
              />
            </div>
            <div>
              <Tooltip title="item_package_dimensions_length">
                Profundo paquete (lenght){" "}
              </Tooltip>
              <Controller
                name="item_package_dimensions_length"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="packageDepth" />
                  </>
                )}
              />
            </div>
            <div>
              Otras medidas{" "}
              <Controller
                name="otherMeasures"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="weight" />
                  </>
                )}
              />
            </div>
            <div>
              size{" "}
              <Controller
                name="size"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="size" />
                  </>
                )}
              />
            </div>
            <div>
              modelName{" "}
              <Controller
                name="modelName"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="modelName" />
                  </>
                )}
              />
            </div>

            <div>
              brand{" "}
              <Controller
                name="brand"
                control={form.control}
                render={({ field }) => (
                  <div>
                    <Select
                      style={{ width: 152 }}
                      {...field}
                      options={[
                        {
                          key: "Moljaus",
                          value: "Moljaus",
                          label: "Moljaus",
                        },
                        {
                          key: "Dashos",
                          value: "Dashos",
                          label: "Dashos",
                        },
                      ]}
                    ></Select>
                    <ErrorMessage form={form} name="size" />
                  </div>
                )}
              />
            </div>
            <div className="d-flex" style={{ gap: "10px" }}>
              <div style={{ width: "80px" }}>
                Capacidad
                <Controller
                  name="capacity"
                  control={form.control}
                  render={({ field }) => (
                    <div>
                      <Input {...field} />
                      <ErrorMessage form={form} name="capacity" />
                    </div>
                  )}
                />
              </div>
              <div style={{ flex: 1 }}>
                Unidad
                <Controller
                  name="capacityUnit"
                  control={form.control}
                  render={({ field }) => {
                    const handleChangeUnit = (unit) => {
                      field.onChange(unit);
                    };
                    return (
                      <div>
                        <UnitSelect
                          selectedUnit={field.value}
                          onUnitChange={handleChangeUnit}
                        />
                        <ErrorMessage form={form} name="capacityUnit" />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ marginRight: "20px" }}>
            <div>
              <span>Precio BreakEven</span>
              <Controller
                name="brakeEvenPrice"
                disabled={!isBaseProduct}
                control={form.control}
                rules={{
                  pattern: {
                    value: /^\d+(\.\d+)?$/,
                    message: "Debe ser un número decimal con punto",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="price" />
                  </>
                )}
              />
            </div>
            <div>
              <span>Precio medio de compra</span>
              <Controller
                name="averagePrice"
                disabled={!isBaseProduct}
                control={form.control}
                rules={{
                  pattern: {
                    value: /^\d+(\.\d+)?$/,
                    message: "Debe ser un número decimal con punto",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="price" />
                  </>
                )}
              />
            </div>
            <div>
              {!isBaseProduct ? (
                <span>Precio total articulos</span>
              ) : (
                <span>Ultimo precio compra</span>
              )}
              <Controller
                name="price"
                disabled={!isBaseProduct}
                control={form.control}
                rules={{
                  pattern: {
                    value: /^\d+(\.\d+)?$/,
                    message: "Debe ser un número decimal con punto",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="price" />
                  </>
                )}
              />
            </div>
            <div>
              <span>Cantidad minima para alerta</span>
              <Controller
                name="minQuantity"
                disabled={!isBaseProduct}
                control={form.control}
                rules={{
                  pattern: {
                    value: /^\d+(\.\d+)?$/,
                    message: "Debe ser un número decimal con punto",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="price" />
                  </>
                )}
              />
            </div>
          </div>

          <div style={{ marginRight: "20px", width: "300px" }}>
            Notas internas
            <Controller
              name={"internalNotes"}
              control={form.control}
              render={({ field }) => {
                return <DGCKeditor {...field} />;
              }}
            />
          </div>

          <div style={{ marginRight: "20px" }}>
            <div>Stock</div>
            <Controller
              name="stock"
              control={form.control}
              rules={{
                pattern: {
                  value: /^\d+$/,
                  message: "Debe ser un número",
                },
              }}
              render={({ field }) => (
                <>
                  <InputNumber {...field} style={{ width: "200px" }} />
                  <ErrorMessage form={form} name="stock" />
                </>
              )}
            />
            <div>
              {warehouseStockFields.map((product, index) => {
                return (
                  <Controller
                    key={index}
                    name={`warehouseStock[${index}]`}
                    control={form.control}
                    render={({ field }) => {
                      const handleChange = (warehouse, e) => {
                        field.onChange({
                          warehouse,
                          stock: parseInt(e || 0),
                        });
                      };
                      return (
                        <div>
                          <div>
                            {
                              warehouses.find(
                                (w: any) => w._id === field.value.warehouse
                              )?.name
                            }
                          </div>
                          <InputNumber
                            style={{ width: "200px" }}
                            onChange={(e) => {
                              handleChange(field.value.warehouse, e);
                            }}
                            value={field.value.stock}
                          />
                        </div>
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div style={{ marginRight: "20px", right: 0, position: "relative" }}>
            <div>
              Sku
              <Controller
                name="sku"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="sku" />
                  </>
                )}
              />
            </div>
            <div>
              Fnsku
              <Controller
                name="fnsku"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="fnsku" />
                  </>
                )}
              />
            </div>
            <div>
              Hscode
              <Controller
                name="hscode"
                control={form.control}
                render={({ field }) => (
                  <>
                    <div className="d-flex">
                      <Input {...field} />{" "}
                      {field.value && (
                        <>
                          <Tooltip
                            title={`Ver en Taric completo (${field.value}). El contenido puedes pasarlo a chatgpt para que te lo explique y ver si hay aranceles u otras cosas`}
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`https://ec.europa.eu/taxation_customs/dds2/taric/measures.jsp?Lang=es&Taric=${field.value}&search_text=goods&LangDescr=es`}
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                                display: "flex",
                              }}
                            >
                              <LinkOutlined />
                            </a>
                          </Tooltip>
                          <Tooltip
                            title={`Ver en Taric parcial (${field.value.substring(
                              0,
                              6
                            )}). El contenido puedes pasarlo a chatgpt para que te lo explique y ver si hay aranceles u otras cosas`}
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`https://ec.europa.eu/taxation_customs/dds2/taric/measures.jsp?Lang=es&Taric=${field.value.substring(
                                0,
                                6
                              )}&search_text=goods&LangDescr=es`}
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                                display: "flex",
                              }}
                            >
                              <LinkOutlined />
                            </a>
                          </Tooltip>
                        </>
                      )}
                    </div>
                    <ErrorMessage form={form} name="hscode" />
                  </>
                )}
              />
            </div>

            <div>
              MoldPurchasePrice
              <Controller
                name="moldPurchasePrice"
                control={form.control}
                render={({ field }) => (
                  <>
                    <Input {...field} />
                    <ErrorMessage form={form} name="moldPurchasePrice" />
                  </>
                )}
              />
            </div>
            <div>
              MoldPurchaseDate
              <Controller
                name="moldPurchaseDate"
                control={form.control}
                render={({ field }) => {
                  return (
                    <>
                      <DatePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : null}
                      />
                      <ErrorMessage form={form} name="moldPurchaseDate" />
                    </>
                  );
                }}
              />
            </div>

            <div>
              <Tooltip title="Calcular unidades compradas desde la fecha seleccionada">
                Calcular cantidad desde fecha
              </Tooltip>
              <DatePicker
                onChange={(date) => {
                  window.open(
                    `/product-buys-calculator/${asin}/${date.format(
                      "YYYY-MM-DD"
                    )}`,
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>

        <Divider />
        <div
          className="dg-item"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ width: "160px", marginRight: "20px" }}>
            Youtube link
          </div>
          <div style={{ marginRight: "20px", flex: 1 }}>
            <Controller
              name="youtubeLink"
              control={form.control}
              render={({ field }) => {
                return (
                  <div className="d-flex">
                    {field.value && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={field.value}
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          display: "flex",
                        }}
                      >
                        <LinkOutlined />
                      </a>
                    )}
                    <Input {...field} />
                    <ErrorMessage form={form} name="stock" />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div
          className="dg-item"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ width: "160px", marginRight: "20px" }}>Canva link</div>
          <div style={{ marginRight: "20px", flex: 1 }}>
            <Controller
              name="canvaLink"
              control={form.control}
              render={({ field }) => {
                return (
                  <div className="d-flex">
                    {field.value && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={field.value}
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          display: "flex",
                        }}
                      >
                        <LinkOutlined />
                      </a>
                    )}
                    <Input {...field} />
                    <ErrorMessage form={form} name="stock" />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div
          className="dg-item"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ width: "160px", marginRight: "20px" }}>A+ link</div>
          <div style={{ marginRight: "20px", flex: 1 }}>
            <Controller
              name="aplusLink"
              control={form.control}
              render={({ field }) => {
                return (
                  <div className="d-flex">
                    {field.value && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={field.value}
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          display: "flex",
                        }}
                      >
                        <LinkOutlined />
                      </a>
                    )}
                    <Input {...field} />
                    <ErrorMessage form={form} name="stock" />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div
          className="dg-item"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ width: "160px", marginRight: "20px" }}>
            Seller central link
          </div>
          <div style={{ marginRight: "20px", flex: 1, display: "flex" }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://sellercentral.amazon.es/inventory/ref=xx_invmgr_favb_xx?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22sortedColumnId%22%3A%22date%22%7D;search:${asin};pagination:1;`}
              style={{
                marginLeft: "10px",
                display: "flex",
                textDecoration: "none",
              }}
            >
              <LinkOutlined />
            </a>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "160px", marginRight: "20px" }}>Amazon link</div>
          <div style={{ marginRight: "20px", flex: 1, display: "flex" }}>
            <Tooltip title="España">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://amazon.es/dp/${asin}`}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <Flag lang={"spanish"} />
              </a>
            </Tooltip>
            <Tooltip title="Alemania">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://amazon.de/dp/${asin}`}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <Flag lang={"german"} />
              </a>
            </Tooltip>
            <Tooltip title="Francia">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://amazon.fr/dp/${asin}`}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <Flag lang={"french"} />
              </a>
            </Tooltip>
            <Tooltip title="Italia">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://amazon.it/dp/${asin}`}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <Flag lang={"italian"} />
              </a>
            </Tooltip>
            <Tooltip title="Bélgica">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://amazon.com.be/dp/${asin}`}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <Flag lang={"belgium"} />
              </a>
            </Tooltip>
            <Tooltip title="Paises bajos">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://amazon.nl/dp/${asin}`}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <Flag lang={"netherlands"} />
              </a>
            </Tooltip>

            <Tooltip title="Polonia">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://amazon.pl/dp/${asin}`}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <Flag lang={"poland"} />
              </a>
            </Tooltip>
            <Tooltip title="Suecia">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://amazon.se/dp/${asin}`}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <Flag lang={"sweden"} />
              </a>
            </Tooltip>
            <Tooltip title="Abrir todas paginas de producto">
              <a
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  window.open(`https://amazon.es/dp/${asin}`, "_blank");
                  window.open(`https://amazon.de/dp/${asin}`, "_blank");
                  window.open(`https://amazon.fr/dp/${asin}`, "_blank");
                  window.open(`https://amazon.it/dp/${asin}`, "_blank");
                  window.open(`https://amazon.com.be/dp/${asin}`, "_blank");
                  window.open(`https://amazon.nl/dp/${asin}`, "_blank");
                  window.open(`https://amazon.se/dp/${asin}`, "_blank");
                  window.open(`https://amazon.pl/dp/${asin}`, "_blank");
                }}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <LinkOutlined />
              </a>
            </Tooltip>
            <Tooltip title="Abrir todas paginas de categorias">
              <a
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  const values = form.getValues();

                  const esUrl = `https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === "spanish")
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_5?ie=UTF8&node=${
                    values.spanish.recommended_browse_nodes
                  }`;
                  const deUrl = `https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === "german")
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_4?ie=UTF8&node=${
                    values.german.recommended_browse_nodes
                  }`;
                  const frUrl = `https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === "french")
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_4?ie=UTF8&node=${
                    values.french.recommended_browse_nodes
                  }`;
                  const itUrl = `https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === "italian")
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_4?ie=UTF8&node=${
                    values.italian.recommended_browse_nodes
                  }`;
                  const beUrl = `https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === "belgium")
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_4?ie=UTF8&node=${
                    values.belgium.recommended_browse_nodes
                  }`;
                  const nlUrl = `https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === "netherlands")
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_4?ie=UTF8&node=${
                    values.netherlands.recommended_browse_nodes
                  }`;
                  const seUrl = `https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === "sweden")
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_4?ie=UTF8&node=${
                    values.sweden.recommended_browse_nodes
                  }`;
                  const plUrl = `https://www.amazon.${
                    MARKETPLACES_IDS.find((el) => el.key === "poland")
                      ?.urlRootDomain
                  }/b/ref=dp_bc_aui_C_4?ie=UTF8&node=${
                    values.poland.recommended_browse_nodes
                  }`;

                  window.open(esUrl, "_blank");
                  window.open(deUrl, "_blank");
                  window.open(frUrl, "_blank");
                  window.open(itUrl, "_blank");
                  window.open(beUrl, "_blank");
                  window.open(nlUrl, "_blank");
                  window.open(seUrl, "_blank");
                  window.open(plUrl, "_blank");
                }}
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <LinkOutlined />
              </a>
            </Tooltip>
          </div>
        </div>
        <Divider />
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "20px", flex: 1 }}>
            Es producto base
            <div>
              <Controller
                name="isBaseProduct"
                control={form.control}
                render={({ field }) => {
                  const handleChange = (data) => {
                    field.onChange(data);
                    setIsBaseProduct(data);
                  };
                  return (
                    <>
                      <Switch checked={field.value} onChange={handleChange} />
                      <ErrorMessage form={form} name="stock" />
                    </>
                  );
                }}
              />
            </div>
          </div>
          {!isBaseProduct && (
            <div style={{ flex: 1 }}>
              {fields.map((product, index) => {
                return (
                  <Controller
                    name={`composedBy[${index}]`}
                    control={form.control}
                    render={({ field }) => (
                      <div>
                        <Link to={`/products/${field.value}`}>
                          {field.value}
                        </Link>
                      </div>
                    )}
                  />
                );
              })}
              <Button onClick={handleComposeProduct}>Componer</Button>
            </div>
          )}
        </div>

        <Divider />
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Tabs
              defaultActiveKey={languageTab}
              items={items}
              onChange={(key) => {
                setLocation(`${location}?languageTab=${key}`);
                setLanguageTab(key);
                setSelectedLanguage(null);
                setTimeout(() => {
                  setSelectedLanguage(key);
                }, 0);
              }}
            />
            {selectedLanguage !== null && !isLoading && (
              <ProductLanguageForm
                asin={asin}
                form={form}
                asinService={asinService}
                selectedLanguage={selectedLanguage}
                awsData={awsData}
              />
            )}
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ flexGrow: 1 }}></div>
          <Button
            disabled={!form.formState.isValid}
            type="primary"
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </div>
      </div>
    </>
  );
};
