import { Select } from "antd";
import React from "react";

const unitOptions = [
  { value: "centiliters", label: "Centilitros" },
  { value: "centiliters_per_second", label: "Centilitros por segundo" },
  { value: "hundredths_pounds", label: "Centésimas de libras" },
  { value: "cubic_centimeters", label: "Centímetros cúbicos" },
  { value: "quarts", label: "Cuartos" },
  { value: "deciliters", label: "Decilitros" },
  { value: "gallons", label: "Galones" },
  { value: "imperial_gallons", label: "Galones imperiales" },
  { value: "grams", label: "Gramos" },
  { value: "kilograms", label: "Kilogramos" },
  { value: "liters", label: "Litros" },
  { value: "pounds", label: "Libras" },
  { value: "milliliters", label: "Mililitros" },
  { value: "ounces", label: "Onzas" },
  { value: "cups", label: "Tazas" },
  { value: "tons", label: "Toneladas" },
  { value: "cubic_meters", label: "Metros cúbicos" },
];

export const UnitSelect = ({ selectedUnit, onUnitChange }) => {
  return (
    <Select
      style={{ width: "100%" }}
      value={selectedUnit}
      onChange={(e) => onUnitChange(e)}
      showSearch
    >
      <option value="">Seleccione una unidad</option>
      {unitOptions.map((unit) => (
        <option key={unit.value} value={unit.value}>
          {unit.label}
        </option>
      ))}
    </Select>
  );
};
