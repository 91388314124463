import React from "react";

export const EtiquetaSeguridad = ({ data }) => {
  return (
    <div
      style={{
        position: "relative",
        height: "27.7mm",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "44px",
          left: "102px",
          fontSize: "11px",
        }}
      >
        {`${data.title.substring(0, 15)}...`}
      </div>

      <div
        style={{
          position: "absolute",
          top: "88px",
          left: "27px",
          fontSize: "11px",
        }}
      >
        {data.modelName}
      </div>

      <img
        style={{
          width: "50.5mm",
          height: "26.5mm",
        }}
        src={
          data.brand.toLowerCase() === "moljaus"
            ? "/plantillaEtiquetaSeguridadMoljaus.png"
            : "/plantillaEtiquetaSeguridadDashos.png"
        }
        alt="etiqueta"
      />

      <img
        src={data.img}
        alt="etiqueta"
        style={{
          position: "absolute",
          top: "57px",
          left: "120px",
          height: "40px",
          width: "40px",
        }}
      />
    </div>
  );
};
