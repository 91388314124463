import React, { useRef } from "react";
import { EtiquetaSeguridad } from "./EtiquetaSeguridad.tsx";
import { useReactToPrint } from "react-to-print";
const styles = {
  a4Page: {
    width: "210mm",
    height: "297mm",
    padding: "0",
    margin: "0 auto",
    background: "white",
    boxSizing: "border-box" as const,
  },
  etiquetasGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 52.5mm)",
    gridGap: "0",
    padding: "0",
  },
  etiqueta: {
    width: "52.5mm",
    height: "29.7mm",
    // border: "1px solid blue",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    boxSizing: "border-box" as const,
  },
};
export const EtiquetasPrintPage = ({ etiquetas, data }) => {
  const contentRef = useRef();

  const handlePrint = useReactToPrint({ contentRef });

  return (
    <div>
      <button onClick={() => handlePrint()}>Descargar PDF</button>

      <div ref={contentRef} id="to-print" style={styles.a4Page}>
        <div style={styles.etiquetasGrid}>
          {etiquetas.map((etiqueta, index) => (
            <div key={index} style={styles.etiqueta}>
              <EtiquetaSeguridad data={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
