import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaRegCopy } from "react-icons/fa6";
import { notification } from "antd";

export const DGCKeditor = ({
  onChange,
  value,
  showCopyButton = false,
}: any) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <CKEditor
        editor={ClassicEditor}
        data={value || ""}
        onChange={(event, editor) => {
          onChange(editor.getData());
        }}
      />
      {showCopyButton && (
        <div style={{ position: "absolute", right: 10, bottom: 10 }}>
          <FaRegCopy
            onClick={() =>
              navigator.clipboard.writeText(value).then(() => {
                notification.success({
                  message: "Copiado",
                  description: "Copiado al portapapeles",
                });
              })
            }
          />
        </div>
      )}
    </div>
  );
};
