import React, { useContext, useEffect, useState } from "react";
import { useParams } from "wouter";
import { DataContext } from "../context/DataContext.tsx";
import { navigate } from "wouter/use-browser-location";
import { AsinService } from "../services/AsinService.ts";
import { Button, Input, notification } from "antd";
import { NotificationService } from "../services/NotificationService.ts";
import { DeleteOutlined } from "@ant-design/icons";

const asinService = new AsinService();

export const DocsPage = () => {
  const [file, setFile] = useState<any>(null);
  const [docTitle, setDocTitle] = useState("");
  const { asin } = useParams();
  const dataContext = useContext(DataContext);
  if (!asin) {
    navigate("/products");
  }
  const product = dataContext.allProducts[asin];
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCreateDoc = async () => {
    if (!file || !docTitle) {
      NotificationService.error("Faltan datos", "Faltan datos");
      return;
    }
    const formData = new FormData();
    formData.append("files", file);
    formData.append("title", docTitle);
    asinService
      .createDoc(asin, formData)
      .then((res) => {
        NotificationService.success("Documento creado", "Documento creado");
        dataContext.refreshData();
      })
      .catch((err) => {
        NotificationService.error(
          "Error al crear documento",
          err.response.data.msg
        );
      });
  };

  return (
    <div className="myspace">
      <div>
        <img height={200} src={product?.spanish?.images?.MAIN} alt="product" />
      </div>
      <div>
        <div>Documentos</div>
        <Input type="file" onChange={handleFileChange} />
      </div>
      <div>
        <label htmlFor="title">Título del documento</label>
        <Input
          id="title"
          type="text"
          onChange={(e) => setDocTitle(e.target.value)}
        />
      </div>
      <div>
        <Button onClick={handleCreateDoc}>Crear documento</Button>
      </div>

      <div style={{ marginTop: 10 }}>
        {product?.docs?.map((el: any) => (
          <div
            key={el.url}
            style={{
              marginBottom: 10,
              backgroundColor: "#fff",
              padding: "8px 10px 8px 10px",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <a href={el.url}>{el.title}</a>{" "}
            <div>
              <DeleteOutlined
                onClick={() => {
                  asinService.deleteDoc(asin, el._id).then(() => {
                    dataContext.refreshData();
                  });
                }}
                style={{
                  fill: "crimson",
                  color: "crimson",
                  fontSize: 18,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
